import { createContext, useContext, useEffect, useRef, useState } from 'react';
// contexts
import { useProductComparison } from 'context/product-comparison.context';

type ContextProps = {
    footerRef: any | null;
    headerRef: any | null;
    recommendedRef: any | null;
    handleFooterScroll: () => void;
    handleHeaderScroll: () => void;
    handleRecommendedScroll: () => void;
    handleClickScroll: (direction: string) => void;
    showScrollArrowLeft: boolean;
    showScrollArrowRight: boolean;
};

const defaultValues: ContextProps = {
    footerRef: null,
    headerRef: null,
    recommendedRef: null,
    handleFooterScroll: () => {},
    handleHeaderScroll: () => {},
    handleRecommendedScroll: () => {},
    handleClickScroll: (direction: string) => {},
    showScrollArrowLeft: false,
    showScrollArrowRight: false,
};

type Props = {
    children: React.ReactNode;
};

const ScrollFooterContext = createContext<ContextProps>(defaultValues);

export const ScrollFooterProvider = ({ children }: Props) => {
    const { drugManufacturer, productGroups } = useProductComparison();
    const headerRef = useRef(defaultValues.headerRef);
    const footerRef = useRef(defaultValues.footerRef);
    const recommendedRef = useRef(defaultValues.recommendedRef);

    const [showScrollArrowLeft, setShowScrollArrowLeft] = useState<boolean>(defaultValues.showScrollArrowLeft);
    const [showScrollArrowRight, setShowScrollArrowRight] = useState<boolean>(defaultValues.showScrollArrowRight);

    const KEY_QUERY_SELECTOR: string = '[id^="row-product-groups"]';

    const DRUGMANUFACTURERS: any = drugManufacturer;
    //boolean render data header && table
    const RENDER_TABLE: boolean = DRUGMANUFACTURERS?.length > 0 && productGroups?.length > 0;
    const isHorizontalScrollPresent: boolean = footerRef?.current?.scrollWidth > footerRef?.current?.clientWidth;

    //check that the scroll reached the right
    const isScrolledToRight = (): boolean => {
        return footerRef?.current?.scrollLeft === footerRef?.current?.scrollWidth - footerRef?.current?.clientWidth;
    };

    //listener for the footer scroll
    const handleFooterScroll = () => {
        if (footerRef?.current) {
            if (footerRef?.current.scrollLeft === 0) {
                setShowScrollArrowLeft(false);
            } else {
                setShowScrollArrowLeft(true);
            }
            if (isScrolledToRight()) {
                setShowScrollArrowRight(false);
            } else {
                setShowScrollArrowRight(true);
            }

            if (headerRef?.current) headerRef.current.scrollLeft = footerRef.current.scrollLeft;
            if (recommendedRef?.current) recommendedRef.current.scrollLeft = footerRef.current.scrollLeft;

            const rows = document.querySelectorAll('[id^="row-product-groups"]');
            if (rows) {
                rows.forEach(function (div) {
                    div.scrollLeft = footerRef.current.scrollLeft;
                });
            }
        }
    };

    //listener for the header scroll
    const handleHeaderScroll = () => {
        if (headerRef?.current && footerRef?.current) {
            footerRef.current.scrollLeft = headerRef.current.scrollLeft;
        }
    };

    //listener for the recommended scroll
    const handleRecommendedScroll = () => {
        if (recommendedRef?.current && footerRef?.current) {
            footerRef.current.scrollLeft = recommendedRef.current.scrollLeft;
        }
    };

    //handler click on scroll arrows
    const handleClickScroll = (direction: string): void => {
        const scrollIncrementOrDecrement = 150;

        // SCROLL TO THE RIGHT
        if (direction === 'right') {
            if (!showScrollArrowLeft) setShowScrollArrowLeft(true);
            if (footerRef?.current) {
                if (isScrolledToRight) {
                    setShowScrollArrowRight(false);
                }

                if (headerRef?.current) headerRef.current.scrollLeft += scrollIncrementOrDecrement;
                if (recommendedRef?.current) recommendedRef.current.scrollLeft += scrollIncrementOrDecrement;

                const rows = document.querySelectorAll(KEY_QUERY_SELECTOR);
                if (rows) {
                    rows.forEach(function (div) {
                        div.scrollLeft += scrollIncrementOrDecrement;
                    });
                }
            }
        } else {
            // SCROLL TO THE LEFT
            // SCROLL REACHED THE LEFT MARGIN
            const isScrolledToLeft = headerRef.current.scrollLeft - scrollIncrementOrDecrement <= 0;

            if (footerRef?.current) {
                if (!showScrollArrowRight) setShowScrollArrowRight(true);

                // SCROLL REACHED THE LEFT MARGIN === TRUE => HIDE LEFT ARROW
                if (isScrolledToLeft) {
                    setShowScrollArrowLeft(false);
                }

                if (headerRef?.current) headerRef.current.scrollLeft -= scrollIncrementOrDecrement;
                if (recommendedRef?.current) recommendedRef.current.scrollLeft -= scrollIncrementOrDecrement;

                const rows = document.querySelectorAll(KEY_QUERY_SELECTOR);
                if (rows) {
                    rows.forEach(function (div) {
                        div.scrollLeft -= scrollIncrementOrDecrement;
                    });
                }
            }
        }
    };

    //listener to hear scroll and hide arrows or show arrows
    useEffect(() => {
        if (!isHorizontalScrollPresent) {
            setShowScrollArrowLeft(false);
            setShowScrollArrowRight(false);
        } else {
            setShowScrollArrowRight(true);
        }
    }, [isHorizontalScrollPresent]);

    //listener to scroll row individual
    // Seleccionar todos los elementos que coincidan con la clase
    const gridElements = document?.querySelectorAll('[class*="Grid_grid-column-drugManufacturers__"]');

    useEffect(() => {
        const handleScroll = (event: any) => {
            const target = event?.target;
            if (footerRef?.current && target) {
                footerRef.current.scrollLeft = target.scrollLeft;
            }
        };

        // Agregar event listener a cada elemento
        gridElements?.forEach((element) => {
            element?.addEventListener('scroll', handleScroll);
        });

        // Cleanup function para remover los event listeners
        return () => {
            gridElements?.forEach((element) => {
                element?.removeEventListener('scroll', handleScroll);
            });
        };
    }, [RENDER_TABLE, footerRef, isHorizontalScrollPresent, gridElements]);
    /* #endregion */

    const value: ContextProps = {
        footerRef,
        headerRef,
        recommendedRef,
        handleFooterScroll,
        handleHeaderScroll,
        handleRecommendedScroll,
        handleClickScroll,
        showScrollArrowLeft,
        showScrollArrowRight,
    };

    return <ScrollFooterContext.Provider value={value}>{children}</ScrollFooterContext.Provider>;
};

export const useScrollFooterContext = () => useContext(ScrollFooterContext);
