import { useState, useEffect, useContext, useCallback } from 'react';
import { SearchBox } from 'components/SearchBoxPdv/search-box';
import ReactScrollTable from 'react-perfect-scrollbar';
import { ThemeContext } from 'styled-components';
import { useInputValue, useModal, useUser } from 'utils/hooks';
import useStyles from './point-of-sale.styles';
import { Table } from 'components/table';
import { Color, TableCell, TableRow } from '@material-ui/core';
import AlertBanner from 'components/alert-banner';
import { usePointOfSale } from 'context';
import { PointOfSale as PointOfSaleType } from 'common/types';
import { useRouter } from 'next/router';
import { useCloseModal } from 'context/close-modal-context';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';
import { EX3991 } from 'config/flags';
import useChangeLanguage from 'utils/hooks/use-change-language';

interface RenderRowItemProps {
  item: any;
  onSelect(item: any): void;
  selected: boolean;
  availableDrugManufacturers?: any;
  value?: any;
}

function RenderRowItem({
  item,
  onSelect,
  selected,
  availableDrugManufacturers,
  value,
}: RenderRowItemProps) {
  const router = useRouter();
  const { setPointOfSale } = usePointOfSale();
  const theme = useContext(ThemeContext);
  const { name, address, reference_name } = item || {};
  const classes = useStyles({
    colors: theme.colors,
  });
  const EX3526 = process.env.NEXT_PUBLIC_EX3526 == 'true';
  const { t } = useTranslation();
  const handleOnClick = () => {
    onSelect(item);
  };

  const redirectToPos = (posId) => {
    router.push(`/${router.query.clientId}/settings/POS/${posId}/posDetails`);
  };

  const handleClick = (item) => {
    if (value) return null;
    setPointOfSale(item);
    redirectToPos(item?.id);
  };

  return (
    <>
      <TableRow
        hover
        role='checkbox'
        tabIndex={-1}
        selected={selected}
        className={classes.tableRow}
        style={{ paddingRight: 16 }}
      >
        <TableCell
          className={classes.tableCell}
          style={{
            cursor: `${value ? 'default' : 'pointer'}`,
          }}
        >
          <div className={classes.rowContainer_PointOfSale}>
            <div
              onClick={handleOnClick}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <span
                style={{
                  cursor: `${value ? 'default' : 'pointer'}`,
                  color: reference_name ? '#ACACAC' : '',
                }}
                className={classes.chipStyleName}
              >
                {name}
              </span>
              {reference_name && (
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: 16,
                    fontFamily: 'Source Sans Pro',
                  }}
                >
                  {reference_name}
                </p>
              )}
              <span
                style={{
                  cursor: `${value ? 'default' : 'pointer'}`,
                }}
                className={classes.chipStyle}
              >
                {address}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '50%',
              }}
            >
              {availableDrugManufacturers < 2 && (
                <Chip
                  style={{
                    cursor: `${value ? 'default' : 'pointer'}`,
                  }}
                  className={classes.chipConectar}
                  size='small'
                  label={
                    EX3526
                      ? t('home.selectedPOS.home_selecterPointofsaleSecond')
                      : `Conectar droguerías`
                  }
                  onClick={() => handleClick(item)}
                />
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

interface PosListProps {
  data: PointOfSaleType[];
  onSelect: (pos: PointOfSaleType) => void;
  currentSelected: PointOfSaleType;
  search: string;
  setLoadingModal?: any;
  onClose?: any;
  setPointOfSale?: any;
  isEfficientPurchase?: any;
  setDisplay?: any;
}

export function PosList({
  data,
  onSelect,
  search,
  setLoadingModal,
  onClose,
  setPointOfSale,
  isEfficientPurchase,
  setDisplay,
}: PosListProps) {
  const router = useRouter();
  const { push, pathname } = router;
  const [controlLength, setControlLength] = useState(false);
  const { setCurrentSelected, currentSelected } = useCloseModal();

  useEffect(() => {
    //Fixear
    if (
      pathname === '/efficient-purchase/product-comparison' &&
      data.length === 1
    ) {
      setControlLength(true);
    }
  }, [pathname]);

  const handleOnSelect = (item: any) => {
    if (item === currentSelected) onSelect(null);

    if (controlLength) return null;
    else {
      onSelect(item);
      setDisplay(false);
      setCurrentSelected(item);
      localStorage.setItem('pdvSelected', JSON.stringify(item));
      if (router?.query?.orderId === undefined) {
        setPointOfSale(item);
        setLoadingModal(true);
        setTimeout(function () {
          onClose();
        }, 1000);
      }
      if (isEfficientPurchase) {
        push(`/${item.client_id}/efficient-purchase/${item?.id}`, undefined, {
          shallow: true,
        });
      }
    }
  };

  return (
    <ReactScrollTable
      style={{
        marginTop: 8,
        marginBottom: 8,
        maxHeight: '352px',
      }}
    >
      <Table
        columns={[
          {
            key: 'img',
          },
        ]}
        data={data}
        noResultsText='No se encontraron resultados'
        search={search}
        renderRow={(item) => (
          <RenderRowItem
            item={item}
            value={controlLength}
            availableDrugManufacturers={item?.availableDrugManufacturers}
            onSelect={handleOnSelect}
            selected={item?.id === currentSelected?.id}
          />
        )}
        loading={false}
      />
    </ReactScrollTable>
  );
}

export function AlertWarning({ show, hidden }) {
  if (!show || hidden) return null;
  const { t } = useTranslation();
  const { user } = useUser({});
  const userLocaleCountryCode = user?.client?.country_alfa_2;
  if (EX3991) useChangeLanguage(userLocaleCountryCode);
  return (
    <AlertBanner variant='light' type='warning'>
      {!EX3991
        ? 'Si cambias el punto de venta se descartará el pedido que está en curso.'
        : t(
            'efficientPurchasing.comparative.efficientPurchasing_comparativeTwentyThree'
          )}
    </AlertBanner>
  );
}

interface ButtonProps {
  variant: string;
  colorName: Partial<Color>;
  buttonTitle: string;
}
interface PointOfSalesProps {
  alertDisabled?: boolean;
  customButton?: ButtonProps;
}

export function PointOfSale({ alertDisabled }: PointOfSalesProps) {
  const theme = useContext(ThemeContext);
  const classes = useStyles({
    colors: theme.colors,
  });
  const [isEmpty, setIsEmpty] = useState<Boolean>(false);
  const { pointOfSale, setPointOfSale } = usePointOfSale();
  const [posName, setPosName] = useState<any>('');
  const [posReference, setPosReference] = useState<string>('');
  const [pointOfSales, setPointOfSales] = useState<Array<PointOfSaleType>>([]);
  const router = useRouter();
  const isEfficientPurchase = router?.asPath?.includes('/efficient-purchase');
  const modal = useModal();
  const search = useInputValue('');
  const user = useUser({})?.user;
  const {
    setLoadingModal,
    currentSelected,
    setCurrentSelected,
  } = useCloseModal();
  const { t } = useTranslation();
  const EX3526 = process.env.NEXT_PUBLIC_EX3526 == 'true';

  const onClose = () => {
    setLoadingModal(false);
    modal.toggle();
  };

  useEffect(() => {
    if (!user) {
      return null;
    }
    setPointOfSales(user?.pointsOfSale);
  }, [user]);

  useEffect(() => {
    let pos = pointOfSales.find((pos) => pos?.id === pointOfSale?.id);
    setPosName(pos?.name);
    setPosReference(pos?.reference_name);
  }, [pointOfSales, pointOfSale]);

  useEffect(() => {
    setCurrentSelected(pointOfSale);
    setIsEmpty(!pointOfSale);
  }, [pointOfSale]);

  const pointOfSaleFilter = useCallback((): Array<PointOfSaleType> => {
    const value = search.value.toLowerCase();
    return pointOfSales.filter(
      (item) =>
        item.address.toLocaleLowerCase().match(value) ||
        item.name.toLocaleLowerCase().match(value)
    );
  }, [search.value]);

  const isSearching = search?.value?.length > 0;
  const handleNoPosNameText = EX3526
    ? t('home.withOutPharmacySelected.home_withoutPharmacySelectedSecond')
    : 'Seleccioná una farmacia';
  const handleSelectPosText = EX3526
    ? t('home.selectedPOS.home_selecterPointofsale')
    : '¿En qué farmacia vas a realizar la compra?';
  return (
    <>
      {!router?.asPath?.includes('/initial-config') && (
        <div className={classes.container_DropdownPost}>
          <SearchBox
            hide={false}
            setLoadingModal={setLoadingModal}
            onClose={onClose}
            setPointOfSale={setPointOfSale}
            isEfficientPurchase={isEfficientPurchase}
            show={!isEmpty}
            hidden={alertDisabled || !isEfficientPurchase}
            data={!isSearching ? pointOfSales : pointOfSaleFilter()}
            onSelectSelect={setCurrentSelected}
            currentSelected={currentSelected}
            search={search.value}
            variant='outlined'
            placeholder={handleSelectPosText}
            value={search.value}
            pointOfSaleName={posName ? posName : handleNoPosNameText}
            border={true}
            onChange={search.onChange}
            onClear={search.clear}
            style={{
              fontSize: '14px',
              width: '327px',
              height: '40px',
              background: 'white',
              borderRadius: 4,
              marginLeft: 16,
              zIndex: 1000,
            }}
          />
        </div>
      )}
    </>
  );
}

export default PointOfSale;
