import { useEffect } from 'react';
// next
import type { AppProps } from 'next/app';
import Head from 'next/head';
// context
import { AppProviders } from 'context';
// rsuite
import { CustomProvider } from 'rsuite';
// locale
import esEs from 'rsuite/locales/es_ES';
import ptBr from 'rsuite/locales/pt_BR';
import enUs from 'rsuite/locales/en_US';
import esAr from 'rsuite/locales/es_AR';

import 'rsuite/dist/rsuite.min.css';
// styles
import 'styles/globals.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'typeface-source-sans-pro';
import 'rc-pagination/assets/index.css';
import axios from 'axios';
// flags
import { LOCALIZATION } from 'config/flags';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useUser } from 'utils/hooks';
import { COUNTRY } from 'utils/country';
import { Worker } from '@react-pdf-viewer/core';

function MyApp({ Component, pageProps }: AppProps) {
    const { userLocaleCountryCode } = useUser({});

    useEffect(() => {
        const loadWidget = async () => {
            // Esperar a que el script se cargue completamente
            await new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = `//js.hs-scripts.com/22726338.js`;
                script.onload = resolve;
                document.head.appendChild(script);
            });

            // @ts-ignore
            if (window.HubSpotConversations) {
                // @ts-ignore
                window.HubSpotConversations?.widget.load();
            }
        };

        loadWidget();

        return () => {
            // @ts-ignore
            window.HubSpotConversations?.widget.remove();

            // @ts-ignore
            window.hsConversationsOnReady = [];
        };
    }, []);

    async function generateTranslations() {
        const filePath = 'utils/translation/translate.xlsx'; // change your path file
        const outputDir = 'locale'; // change your path directory

        try {
            const response = await axios.post(
                '/api/translate',
                {
                    filePath,
                    outputDir,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );

            if (response.status === 200) {
                const data = response.data;
                console.log(data.message);
            } else {
                console.error('Failed to generate translation files:', response.data);
            }
        } catch (error) {
            console.error('Error generating translation files:', error);
        }
    }

    useEffect(() => {
        !!LOCALIZATION && (async () => await generateTranslations())();
    }, []);

    let lang;
    switch (userLocaleCountryCode) {
        case COUNTRY.MX || COUNTRY.CO || COUNTRY.ES: {
            lang = esEs;
            break;
        }
        case COUNTRY.AR: {
            lang = esAr;
            break;
        }
        case COUNTRY.BR: {
            lang = ptBr;
            break;
        }
        case COUNTRY.US: {
            lang = enUs;
            break;
        }
        default: {
            lang = esEs;
            break;
        }
    }
    return (
        <CustomProvider locale={lang}>
            <Worker workerUrl="/workerPDF/pdf.worker.min.js">
                <AppProviders>
                    <Head>
                        <title>Extendeal Pharma</title>
                    </Head>
                    <Component {...pageProps} />
                </AppProviders>
            </Worker>
        </CustomProvider>
    );
}

export default MyApp;
