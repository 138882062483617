import { useState } from 'react';

export const useStatesFilters = () => {
    const [isFilteringByOutofStock, setIsFilteringByOutofStock] = useState(false);
    const [ isFilteringByGenerics, setIsFilteringByGenerics ] = useState(false);

    return {
        isFilteringByOutofStock,
        setIsFilteringByOutofStock,
        setIsFilteringByGenerics,
        isFilteringByGenerics
    };
};
