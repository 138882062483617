import { createContext, useContext, useRef } from 'react';

type ContextProps = {
    containerRef: any | null;
};

const defaultValues: ContextProps = {
    containerRef: null,
};

type Props = {
    children: React.ReactNode;
};

const ContainerRefContext = createContext<ContextProps>(defaultValues);

export const ContainerRefProvider = ({ children }: Props) => {
    const containerRef = useRef<any | null>(defaultValues.containerRef);

    // Memoize the context value to avoid unnecessary re-renders
    const value: ContextProps = {
        containerRef,
    };

    return <ContainerRefContext.Provider value={value}>{children}</ContainerRefContext.Provider>;
};

export const useContainerRefContext = () => useContext(ContainerRefContext);
