export const deepCompare = (obj1, obj2) => {
  if (
    Object.prototype.toString.call(obj1) ===
    Object.prototype.toString.call(obj2)
  ) {
    if (
      Object.prototype.toString.call(obj1) === "[object Object]" ||
      Object.prototype.toString.call(obj1) === "[object Array]"
    ) {
      if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
      }
      return Object.keys(obj1).every(function (key) {
        return deepCompare(obj1[key], obj2[key]);
      });
    }
    return obj1 === obj2;
  }
  return false;
};

export const removeArrayObjectDuplication = (array) => {
  let newArray = new Set(array.map((arr) => JSON.stringify(arr)));
  return Array.from(newArray).map((arr) => JSON.parse(arr as string));
};

export const isEmptyObjectAtrribute = (object): boolean =>
  Object.values(object).every((obj) => obj == null || obj === undefined);
