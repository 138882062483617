import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import useSWR from 'swr';
// types
import { User, ResponseType, Role } from 'common/types';

interface Props {
    redirectTo: string;
    redirectIfFound: boolean;
}

export function useUser({ redirectTo, redirectIfFound }: Partial<Props>) {
    const { data: user, mutate: mutateUser, error, isValidating } = useSWR<ResponseType<User>>(`/api/users/me`, {
        revalidateOnFocus: false, // No revalidar al recuperar el foco
        dedupingInterval: 300000, // 5 minutos para evitar solicitudes duplicadas
        shouldRetryOnError: false, // No reintentar en caso de error
    });
    const isAuthenticated = !!user?.data;
    const router = useRouter();
    const hasToken = Cookies.get('token');
    const hasAuthorization = user?.data?.role_id === Role.Admin;
    const userLocaleCountryCode = user?.data?.client?.country_alfa_2;

    useEffect(() => {
        if (user && user?.data) {
            // It is redirected to the login when the FF is activated
            localStorage.setItem('redirectToLogin', JSON.stringify(true));
        }

        if ((!redirectTo || !user) && hasToken && !error) {
            return;
        }
        if ((redirectTo && !redirectIfFound && !isAuthenticated) || (redirectIfFound && isAuthenticated)) {
            router.replace(redirectTo);
        }
    }, [user, redirectIfFound, redirectTo, isAuthenticated, hasToken, error]);

    return {
        user: user?.data,
        userLocaleCountryCode,
        hasAuthorization,
        isLoading: !error && !user?.data,
        mutateUser,
        isValidating,
    };
}
